import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';
import moment from 'moment/src/moment';

export default function useFormsList() {
    // Use toast
    const toast = useToast();

    const refFormListTable = ref(null);
    const styleColumns = {
        thClass: 'text-center', 
        tdClass: 'text-center'
    }
    // Table Handlers
    let tableColumns = [
        { key: 'month_and_year', sortable: false, label: 'Mes/Año', ...styleColumns },
        { key: 'state', sortable: true, label: 'Estado', ...styleColumns },
        { key: 'start_date', sortable: true, label: 'Fecha de Inicio', ...styleColumns },
        { key: 'deadline', sortable: true, label: 'Fecha Límite', ...styleColumns },
        // { key: 'observations_count', sortable: true, label: 'Cantidad de Observaciones', ...styleColumns },
        // { key: 'observations', sortable: false, label: 'Ver Observaciones', ...styleColumns },
        { key: 'report_download', sortable: false, label: 'Descargar Informe', ...styleColumns },
        { key: 'options', label: 'Opciones', ...styleColumns },
    ];

    const perPage = ref(10);
    const totalUsers = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref('start_date');
    const isSortDirDesc = ref(true);

    const filterState = ref(null);
    const filterStartDate = ref(null);
    const filterEndDate = ref(null);
    const filterSD = ref(null);
    const filterED = ref(null);

    const dataMeta = computed(() => {
        const localItemsCount = refFormListTable.value ? refFormListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalUsers.value,
        }
    });

    const refetchData = () => {
        refFormListTable.value.refresh();
    };

    watch([currentPage, perPage, filterState, filterStartDate, filterEndDate], () => {
        filterSD.value = moment(filterStartDate.value, "DD-MM-YYYY").toISOString();
        filterED.value = moment(filterEndDate.value, "DD-MM-YYYY").toISOString();
        refetchData();
    });

    const fetchForms = (ctx, callback) => {

        let filterStateArray = [];
        if (filterState.value) {
            filterState.value.map(item => {
                filterStateArray.push(item.value);
            });
        }

        store
            .dispatch('app-provider-form/fetchForms', {
                provider_id: router.currentRoute.params.id,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                filterState: filterState.value ? filterStateArray.join(',') : null,
                filterStartDate: filterSD.value ? filterSD.value.split('T')[0] : null,
                filterEndDate: filterED.value ? filterED.value.split('T')[0] : null,
                role: JSON.parse(localStorage.getItem('userData')).role,
                provider: JSON.parse(localStorage.getItem('userData')).provider,
            })
            .then(response => {
                const { users, total } = response.data;
                callback(users);
                totalUsers.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener la lista de formularios',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                });
            })
    };

    return {
        fetchForms,
        tableColumns,
        perPage,
        currentPage,
        totalUsers,
        dataMeta,
        perPageOptions,
        sortBy,
        isSortDirDesc,
        refFormListTable,

        refetchData,

        filterState,
        filterStartDate,
        filterEndDate
    }
}